/**
 *
 *  AdminFormik
 *
 *
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import Spinner from 'components/Spinner';
import { SearchInput } from 'components/Elements/Input';
import Checkbox from 'components/Elements/Checkbox';
// import Button from 'components/Elements/Button';
import { StyledField, StyledError } from 'components/lib/Formik';

import validate from './validators';

const initValues = {
  email: '',
  confirmTransfer: '',
};

const AdminFormik = ({
  submitText,
  initialValues = initValues,
  onSubmit,
  isTransfer,
}) => {
  const handleChange = useCallback((field, setFieldValue) => evt => {
    const { value } = evt.target;
    setFieldValue(field, value);
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={values => validate(values, isTransfer)}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        submitForm,
        // eslint-disable-next-line
        ...rest
      }) => {
        //
        return (
          <Form style={{ position: 'relative' }}>
            {/* <Spinner loading={isSubmitting} cover /> */}
            {isTransfer && (
              <StyledField
                type="checkbox"
                name="confirmTransfer"
                placeholder="Transfer"
                component={Checkbox}
                initialValue={!!values.confirmTransfer}
                checked={!!values.confirmTransfer}
                touched={touched}
                errors={errors}
                value={values.confirmTransfer}
                onBlur={() => setFieldTouched('confirmTransfer', true)}
                onChange={isChecked =>
                  setFieldValue('confirmTransfer', isChecked)
                }
                renderLabel={() => (
                  <CheckboxLabel>
                    By transferring this account I acknowledge that I am
                    relinquishing my ability to administer the account.
                  </CheckboxLabel>
                )}
              />
            )}

            <StyledField
              type="email"
              name="email"
              placeholder="User Email"
              searchText={submitText}
              value={values.email}
              component={SearchInput}
              onClick={submitForm}
              onBlur={() => setFieldTouched('email', true)}
              onChange={handleChange('email', setFieldValue)}
              touched={touched}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

AdminFormik.propTypes = {
  submitText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isTransfer: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default AdminFormik;

const CheckboxLabel = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1rem;
  width: 75%;
  padding-left: 15px;
`;
