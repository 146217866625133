/**
 *
 * Edit Ripspot - Controls to select section to edit
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import Button from 'components/Elements/Button';

// import useMeasure from 'utils/hooks/useMeasure'

const EditRipspot = ({ isOpen, ripspot, onEditRipspotClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  // const [bind, { height }] = useMeasure();
  const maxHeight = 1000;
  const props = useSpring({
    maxHeight: isOpen ? maxHeight : 0,
    opacity: isOpen ? 1 : 0,

    marginTop: isOpen ? '20px' : '0px',
  });

  return (
    <animated.div
      style={{ marginBottom: '20px', overflow: 'hidden', ...props }}
    >
      <Container isOpen={isOpen}>
        {/* <Title>Edit</Title> */}
        <Description>Select which step you want to edit:</Description>
        <List>
          <li>
            <StyledButton light onClick={onEditRipspotClick(ripspot)}>
              Map
            </StyledButton>
          </li>
          <li>
            <StyledButton light onClick={onEditRipspotClick(ripspot, 1)}>
              Details
            </StyledButton>
          </li>
          <li>
            <StyledButton light onClick={onEditRipspotClick(ripspot, 2)}>
              Bio
            </StyledButton>
          </li>
          <li>
            <StyledButton light onClick={onEditRipspotClick(ripspot, 3)}>
              Photos
            </StyledButton>
          </li>
        </List>
      </Container>
    </animated.div>
  );
};

EditRipspot.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ripspotId: PropTypes.string.isRequired,
  ripspot: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default EditRipspot;

const Container = styled.div`
  padding-bottom: 30px;
  visibility: hidden;
  transition: visibility 0.3s ease 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      transition: visibility 0.3s;
    `}
`;

const StyledButton = styled(Button)``;

const Title = styled.h2`
  margin-bottom: 15px;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-bottom: 15px;
`;

const List = styled.ul`
  display: flex;
  list-style: none;

  li {
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-right: 1em;
  }
`;
