/**
 *
 * AdminItem
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import CloseButton from 'components/CloseButton';
import ButtonIcon from 'components/Elements/ButtonIcon';
import CloseIcon from 'components/icons/Close';

const AdminItem = ({ adminUser, onClick }) => {
  //
  return (
    <Item key={adminUser.id}>
      <ButtonIcon
        Icon={CloseIcon}
        width={20}
        height={20}
        fill="#000"
        style={{ marginRight: 15 }}
        onClick={onClick}
      />
      <span>{adminUser.email}</span>
    </Item>
  );
};

AdminItem.propTypes = {
  adminUser: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AdminItem;

const Item = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  border: 1px solid lightgrey;
`;
