/**
 *
 * Account UTILS
 *
 *
 */

import { useEffect, useState } from 'react';

import { populateRipspotBioMarkup } from 'utils/helperFunctions/ripspots';

/**
 * Handles all logic for getting raw HTML from
 * the rawJson ripspot data
 *
 *
 * @param {Function} { setUser }
 * @param {Function} { setRipspots }
 * @param {Function} { ripspotData }
 */
export function useGetRipspotsLogic({ user }) {
  const [ripspots, setRipspots] = useState([]);
  useEffect(() => {
    if (user && user.ripspots) {
      const ripspotsWithBioMarkup = populateRipspotBioMarkup(user.ripspots);
      setRipspots(ripspotsWithBioMarkup);
    }
  }, [user]);

  return {
    ripspots,
  };
}
