/**
 *
 * Validators for LoginFormik
 *
 *
 */

export default (values, isTransfer) => {
  const errors = {};

  switch (true) {
    case !values.email:
      errors.email = 'Required';
      break;
    case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email):
      errors.email = 'Email not valid';
      break;
    case isTransfer:
      if (!values.confirmTransfer)
        errors.confirmTransfer = 'You must confirm transfer of ownership.';
      break;
    default:
  }

  return errors;
};
