import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Button from 'components/Elements/Button';
import Modal from 'components/modals/index';
import Spinner from 'components/Spinner';
import { getComments, deleteComment } from 'utils/api/comments';
import { useRefMounted } from 'utils/hooks';
import cls from './CommentsList.module.scss';

const CommentsList = ({ ripspot }) => {
  const isMountedRef = useRefMounted();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [commentIdToDelete, setCommentIdToDelete] = useState('');

  const getCommentsData = useCallback(async () => {
    try {
      setLoading(true);
      const commentsRes = await getComments(ripspot.id);

      if (isMountedRef.current) {
        setData([...commentsRes]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [isMountedRef]);


  useEffect(() => {
    getCommentsData();
  }, [getCommentsData]);

  const handleDeleteComment = async commentId => {
    try {
      setLoading(true);
      await deleteComment(commentId);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      getCommentsData();
    }
  };

  if (loading) {
    return (
      <div className={cls.spinnerWrapper}>
        <Spinner loading cover={true} size={50} />
      </div>
    );
  }

  return (
    <>
      <div>
        {data.length === 0 ? (
          <div className={cls.noComments}>No memories yet.</div>
        ) : (
          <>
            {data.map(comment => (
              <div className={cls.listItem} key={`comment-${comment._id}`}>
                <div className={cls.name}>
                  <span className={cls.label}>Name:</span> {comment.name}
                </div>
                <div className={cls.message}>
                  <span className={cls.label}>Email:</span> {comment.email}
                </div>
                <div className={cls.message}>
                  <span className={cls.label}>Message:</span> {comment.message}
                </div>
                <div className={cls.action}>
                  <Button
                    dangerous
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setCommentIdToDelete(comment._id);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
        <Modal
          closeOnOutsideClick
          isOpen={confirmModalOpen}
          onRequestClose={() => {
            setConfirmModalOpen(false);
          }}
          closeIconSize="md"
        >
          <Container>
            <Wrapper>
              <Title>Are you sure you want to delete this memory?</Title>
              <Controls>
                <Button
                  style={{ marginRight: '25px' }}
                  primary
                  onClick={async () => {
                    if (commentIdToDelete) {
                      await handleDeleteComment(commentIdToDelete);
                    }
                    setConfirmModalOpen(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  dangerous
                  onClick={() => {
                    setConfirmModalOpen(false);
                  }}
                >
                  No
                </Button>
              </Controls>
            </Wrapper>
          </Container>
        </Modal>
      </div>
    </>
  );
};

export default CommentsList;

const Container = styled.div`
  ${({ theme }) => theme.styles.middle}
`;
const Wrapper = styled.div`
  ${({ theme }) => theme.styles.boxShadows['0']};
  background-color: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 55px;

  button {
    min-width: 140px;
  }
`;
const Title = styled.h2`
  font-size: 1.4rem;
`;
