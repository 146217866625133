/**
 *
 * AdminRipspot -- Admin area for ripspot
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { toastSuccess, toastError } from 'utils/toastify';

// import useMeasure from 'utils/hooks/useMeasure';

import {
  addAdminToRipspot,
  removeAdminFromRipspot,
  transferOwnershipOfRipspot,
} from 'utils/api/ripspots';
import { getUserById } from 'utils/api/users';
import { setUser } from 'actions/user';

import AdminFormik from './AdminFormik';
import AdminItem from './AdminItem';

const sharedUsers = [
  { email: 'modoe@gmail.com' },
  { email: 'aldoe@gmail.com' },
];

const AdminRipspot = ({ isOpen, ripspotId, ripspot, user, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);

  // const [bind, { height }] = useMeasure();
  const maxHeight = 1000;
  const props = useSpring({
    maxHeight: isOpen ? maxHeight : 0,
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translate3d(0,0,0)' : 'translate3d(20px,0,0)',
  });

  /**
   *
   *
   * @param {*} values
   * @param {*} { setSubmitting }
   */
  const handleShareSubmit = async (
    values,
    { setSubmitting, setFieldError },
  ) => {
    try {
      setIsLoading(true);
      const { email } = values;

      const res = await addAdminToRipspot(ripspotId, { email });
      const updatedUser = await getUserById(user.id);
      dispatch(setUser(updatedUser));

      // need to update ripspot in store with ne wowner
      setIsLoading(false);
      setSubmitting(false);
      toastSuccess(`Admin rights shared with ${email}`);
    } catch (err) {
      setIsLoading(false);
      setSubmitting(false);
      setFieldError('email', err.message);
    }
  };

  const handleTransfer = async email => {
    try {
      setIsLoading(true);

      const res = await transferOwnershipOfRipspot(ripspotId, { email });
      const updatedUser = await getUserById(user.id);
      dispatch(setUser(updatedUser));

      setIsLoading(false);
      toastSuccess(`Ownership transfered to ${email}`);
    } catch (err) {
      setIsLoading(false);
      toastError('Failed to transfer ownership');
    }
  };

  const handleAdminRemoveClick = async adminItem => {
    try {
      setIsLoading(true);

      const res = await removeAdminFromRipspot(ripspotId, {
        email: adminItem.email,
      });
      const updatedUser = await getUserById(user.id);
      dispatch(setUser(updatedUser));

      setIsLoading(false);
      toastSuccess(`Admin rights removed for ${adminItem.email}`);
    } catch (err) {
      setIsLoading(false);
      toastError('Failed to remove admin');
    }
  };

  const admins = ripspot && ripspot.ownership && ripspot.ownership.admins;

  return (
    <animated.div
      style={{ marginBottom: '20px', overflow: 'hidden', ...props }}
    >
      <Container isOpen={isOpen}>
        {/* TOP */}
        <FormSection>
          <Title>Admin</Title>
          <Description>
            Note: The email address you are sharing with must have an associated
            Ripspot account. Please ensure they have signed up prior to sharing.
          </Description>
          {/* // Share FORM */}
          <AdminFormik submitText="Share" onSubmit={handleShareSubmit} />
          <CurrentAdmin>
            {admins && admins.length > 0 ? (
              <>
                <label htmlFor={`${ripspotId}-shared-list`}>
                  Currently sharing with:
                </label>
                <List id={`${ripspotId}-shared-list`}>
                  {admins.map(adminUser => (
                    <AdminItem
                      key={adminUser._id || adminUser.email}
                      adminUser={adminUser}
                      onClick={() => handleAdminRemoveClick(adminUser)}
                    />
                  ))}
                </List>
              </>
            ) : (
              <label>Not currently sharing Ripspot.</label>
            )}
          </CurrentAdmin>
        </FormSection>
        {/* BOTTOM */}
        <FormSection>
          <Title>Transfer</Title>
          <Description>
            Transfer ownership of this account to another user. Simply enter
            their email address.
          </Description>
          {/* // Transfer FORM */}
          <AdminFormik
            isTransfer
            submitText="Transfer"
            onSubmit={values => handleTransfer(values.email)}
          />
        </FormSection>
      </Container>
    </animated.div>
  );
};

AdminRipspot.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ripspotId: PropTypes.string.isRequired,
  ripspot: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AdminRipspot;

const Container = styled.div`
  padding-bottom: 30px;
  visibility: hidden;
  transition: visibility 0.3s ease 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      transition: visibility 0.3s;
    `}
`;

const FormSection = styled.div`
  margin: 15px 0 20px;

  ${({ theme }) => theme.media.max.tablet`
    &:nth-of-type(2) {
      margin-top: 25px;
    }
  `}
  ${({ theme }) => theme.media.min.tablet`
    flex: 1;
    // &:first-of-type {
    //   margin-right: 25px;
    // }
  `}
  // ${({ theme }) => theme.media.min.desktop`
  //   &:first-of-type {
  //     margin-right: 55px;
  //   }
  // `}
`;

const Title = styled.h2`
  margin-bottom: 15px;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-bottom: 15px;
`;

const CurrentAdmin = styled.div`
  margin-top: 15px;
  font-size: 1rem;
  label {
    color: ${({ theme }) => theme.colors.darkGrey};
    display: inline-block;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;

  li {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
`;
