/**
 *
 * Ripspot card for dashboard
 *
 *
 */

import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import ReactHtmlParser from 'react-html-parser';
// import { navigate } from 'gatsby';
import Modal from 'components/modals';
import Button from 'components/Elements/Button';

import Theme from 'utils/theme';

import AdminRipspot from './AdminRipspot';
import EditRipspot from './EditRipspot';
import { CommentsList } from './CommentsList';

const { colors } = Theme;

const RipspotCard = ({
  ripspot,
  user,
  dispatch,
  onViewRipspotClick,
  onEditRipspotClick,
  onDeleteRipspotClick,
  onIsActiveClick,
}) => {
  const [adminIsOpen, setAdminIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [commentsModalOpened, setCommentsModalOpened] = useState(false);
  const {
    name,
    location,
    markup,
    id,
    isComplete,
    isActive,
    isPublic,
    birthDate,
    deathDate,
  } = ripspot;

  const ownerId = ripspot.ownership.owner.id;
  const userId = user.id;

  const isActiveProps = {
    style: {
      width: '105px',
    },
  };

  if (!isActive) {
    isActiveProps.outline = true;
    isActiveProps.style = {
      ...isActiveProps.style,
      color: colors.lightGreen,
    };
  } else {
    // INactive
    // isActiveProps.tertiary = true;
    isActiveProps.style = {
      ...isActiveProps.style,
      backgroundColor: colors.midGreen,
      border: `1px solid ${colors.midGreen}`,
      color: '#fff',
    };
  }

  const tempName = `New Ripspot ${moment(ripspot._updatedAt).format(
    'MM-DD-YYYY hh-mm-ss',
  )}`;

  const ripspotName = name
    ? `${name} ${birthDate?.year ? `| ${birthDate.year}` : ''} ${
        deathDate?.year ? `- ${deathDate.year}` : ''
      }`
    : tempName;

  return (
    <Container>
      <Title>{ripspotName}</Title>
      <Controls>
        {isComplete && (
          <Button primary onClick={onViewRipspotClick(ripspot)}>
            View
          </Button>
        )}
        {ownerId === userId && (
          <Button
            primary={!adminIsOpen}
            secondary={adminIsOpen}
            onClick={() => setAdminIsOpen(!adminIsOpen)}
          >
            Admin
          </Button>
        )}
        <Button primary onClick={() => setEditIsOpen(!editIsOpen)}>
          {isComplete ? `Edit` : `Finish`}
        </Button>
        {isComplete && (
          <Button {...isActiveProps} onClick={onIsActiveClick(ripspot)}>
            {isActive ? 'Active' : 'Inactive'}
          </Button>
        )}
        <Button primary onClick={() => setCommentsModalOpened(true)}>
          View Memories
        </Button>
        {ownerId === userId && (
          <Button
            dangerous
            onClick={onDeleteRipspotClick && onDeleteRipspotClick(ripspot)}
          >
            Delete
          </Button>
        )}
      </Controls>
      <EditRipspot
        isOpen={editIsOpen}
        onEditRipspotClick={onEditRipspotClick}
        ripspot={ripspot}
      />
      {ownerId === userId && (
        <AdminRipspot
          isOpen={adminIsOpen}
          ripspotId={id}
          ripspot={ripspot}
          user={user}
          dispatch={dispatch}
        />
      )}
      <Modal
        isOpen={commentsModalOpened}
        closeOnOutsideClick
        onRequestClose={() => {
          setCommentsModalOpened(false);
        }}
        title="Memories"
      >
        <ModalContainer>
          <CommentsList ripspot={ripspot} />
        </ModalContainer>
      </Modal>
    </Container>
  );
};

RipspotCard.propTypes = {
  ripspot: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  onViewRipspotClick: PropTypes.func.isRequired,
  onEditRipspotClick: PropTypes.func.isRequired,
  onDeleteRipspotClick: PropTypes.func.isRequired,
  onIsActiveClick: PropTypes.func.isRequired,
};

export default RipspotCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
`;

// const StyledButton = styled(Buttmn)`
//   ${{isActive => isActive && css`

//   `}}
//   ${{Inactive => Inactive && css``}}
//   `

const Controls = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.max.mobile`
    flex-wrap: wrap;
  `}

  button {
    font-size: 1.1rem;
    margin-right: 15px;
    ${({ theme }) => theme.media.max.mobile`
      margin-bottom: 15px;  
    `}
  }
`;

const ModalContainer = styled.div`
  width: 600px;
  padding: 2rem;

  @media screen and (max-width: 640px) {
    width: calc(100vw - 60px);
  }
`;
